import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TEST_CONSULTANCY_SERVER_PREFIX}/test-service-map`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    create: () => `${RESOURCE_ENDPOINT}`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    getTestServiceMapById: (id: any) => `${RESOURCE_ENDPOINT}/getTestServiceMapById/${id}`,
    getCAndFValueByServiceMappingVariationId: (id: any) => `${RESOURCE_ENDPOINT}/getCAndFValueByServiceMappingVariationId/${id}`,
    getUnitwiseByServiceMappingVariationId: (id: any) => `${RESOURCE_ENDPOINT}/getUnitwiseByServiceMappingVariationId/${id}`,
    getSubParameterByServiceMappingVariationId: (id: any) => `${RESOURCE_ENDPOINT}/getSubParameterByServiceMappingVariationId/${id}`,
    getOrganizationBySearch: (id) => `${RESOURCE_ENDPOINT}/getOrganizationBySearch/${0}`,
    getTestServiceBySearch: (organizationId: any,sampleId: any) => `${RESOURCE_ENDPOINT}/getTestServiceBySearch/${organizationId}/${sampleId}`,
    getTestServiceElementBySearch: (organizationId: any,sampleId: any) => `${RESOURCE_ENDPOINT}/getTestServiceElementBySearch/${organizationId}/${sampleId}`,

    getCAndFValueByServiceMappingId: (id: any) => `${RESOURCE_ENDPOINT}/getCAndFValueByServiceMappingId/${id}`,
    getServiceMapList: () => `${RESOURCE_ENDPOINT}/getServiceMapList`,
    getSampleParameterList: () => `${RESOURCE_ENDPOINT}/getSampleParameterList`,
}

export default class TestServiceMapApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    }

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    }

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    }

    public create = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    }

    public update = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    }

    public updatePartial = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    }

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    }

    public bulk = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    }

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    }

    public getCAndFValueByServiceMappingVariationId = (testServiceMappingVariationId: any): AxiosPromise<any> => {
        const url = endpoints.getCAndFValueByServiceMappingVariationId(testServiceMappingVariationId);
        return HttpService.get(url);
    }

    public getUnitwiseByServiceMappingVariationId = (testServiceMappingVariationId: any): AxiosPromise<any> => {
        const url = endpoints.getUnitwiseByServiceMappingVariationId(testServiceMappingVariationId);
        return HttpService.get(url);
    }

    public getSubParameterByServiceMappingVariationId = (testServiceMappingVariationId: any): AxiosPromise<any> => {
        const url = endpoints.getSubParameterByServiceMappingVariationId(testServiceMappingVariationId);
        return HttpService.get(url);
    }

    public getOrganizationBySearch = (): AxiosPromise<any> => {
        const url = endpoints.getOrganizationBySearch(0);
        return HttpService.get(url);
    }

    public getTestServiceBySearch = (organizationId: any, sampleId: any): AxiosPromise<any> => {
        const url = endpoints.getTestServiceBySearch(organizationId,sampleId);
        return HttpService.get(url);
    }

    public getTestServiceElementBySearch = (organizationId: any, sampleId: any): AxiosPromise<any> => {
        const url = endpoints.getTestServiceElementBySearch(organizationId,sampleId);
        return HttpService.get(url);
    }

    public getTestServiceMapById = (testServiceMapId: any): AxiosPromise<any> => {
        const url = endpoints.getTestServiceMapById(testServiceMapId);
        return HttpService.get(url);
    }

    public getCAndFValueByServiceMappingId = (testServiceMapId: any): AxiosPromise<any> => {
        const url = endpoints.getCAndFValueByServiceMappingId(testServiceMapId);
        return HttpService.get(url);
    }
    public getServiceMapList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getServiceMapList();
        return HttpService.get(url, params, headers);
      }

      public getSampleParameterList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSampleParameterList();
        return HttpService.get(url, params, headers);
      }

}
